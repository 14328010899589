<template>
  <el-header height="80px" class="wrap">
    <div id="logo">  <img src="../assets/logo.jpg" width="220px" height="100px"></div>
    <ul id="nav">
      <li v-for="(item,index) in navlist" :key="index">
        <!-- <el-link :href="item.link">{{item.title}}</el-link> -->
        <router-link :to="item.link">{{item.title}}</router-link>
      </li>
    </ul>
  </el-header>

</template>

<script>
  export default {
    data() {
      return {
        navlist: [{
            title: '首页',
            link: '/'
          },
          {
            title: '电商系统',
            link: '/ecerp'
          },
          {
            title: 'CRM系统',
            link: '/crm'
          },
          {
            title: '新零售店长通',
            link: '/nsale'
          },
          {
            title: '客服系统',
            link: '/im'
          },
          {
            title: '溯源系统',
            link: '/trace'
          },
          {
            title: '医疗相关',
            link: '/medical'
          },
          {
            title: '关于我们',
            link: '/about'
          }
        ]
      }
    },
    props: {
      // navList: {
      //   type: Array,
      //   default: () => []
      // }
    }
  }
</script>

<style lang="less" scoped>
  #nav {
    display: flex;
    align-items: center;

    a {
      padding: 10px 15px;
      font-size: 16px;
    }
  }
</style>
