<template>
  <div class="cont3 wrap">
    <ul class="parent">
      <li v-for="(item,index) in list" :key="index">
        <i :class="item.icon"></i>
        <h3 class="title">{{item.title}}</h3>
        <div class="desc">{{item.desc}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="less" scoped>
  .cont3 {
    padding: 60px 0;

    .parent {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;

      li {
        background: #FFF;
        padding: 15px 10px;
        border: 1px solid #2c3e50;
        transition: all 0.25s ease;
        text-align: center;

        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          transform: scale(1.1, 1.1);

          .title,
          [class^=el-icon-] {
            color: #0080ff;
          }
        }
      }

      .title {
        font-size: 18px;
        line-height: 36px;
      }

      .desc {
        font-size: 14px;
        color: #acb2ba;
        text-align: left;
      }

      [class^=el-icon-] {
        font-size: 48px;
      }
    }
  }
</style>