<template>
  <div class="container">

    <Head></Head>
    <el-carousel :height="carousel.height">
      <el-carousel-item>
        <div class="banner" style="background: #2979FF;">
          <div class="wrap">
            <div class="txt">
              <h1>电商ERP整体解决方案</h1>
              <div class="subtitle">商品管理、库存管理、订单管理、财务管理、供应商管理、门店管理、分销管理</div>
              <div>· 实时精准库存同步，智能库存预警，防缺货防超卖</div>

              <div>· 多店铺批量快速打单、发货，效率提升90%</div>

              <div>· 稳定、安全，让您体验极致的电商管理软件</div>
                <div>在线客服</div>
            </div>
            <div class="img">
              <img src="../assets/Class.svg">
            </div>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="banner" style="background: #00BCD4;">
          <div class="wrap">
            <div class="img">
              <img src="../assets/c4.png">
            </div>
            <div class="txt">
              <h1>CRM系统</h1>
              <div class="subtitle">打破销售壁垒，助力企业高效经营业绩提升</div>
              <div>· 全流程销售自动化，提升企业销售效率促进成交</div>

              <div>· 多渠道客户统一管理，自定义客户标签，深度经营企业客户资产</div>

              <div>· 大数据分析，有效管控销售行为，提升销售行动力</div>
            </div>

          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="banner" style="background: #aa55ff;">
          <div class="wrap">
            <div class="txt">
              <h1>在线客服系统</h1>
              <div class="subtitle">全渠道一站式接入，实现一体化管理</div>
              <div>· 网站、微信、小程序、短信、APP等主流渠道，一个平台统一界面，实现统一渠道沟通</div>

              <div>· 多店以客户为中心的视角，提供一致的客服体验</div>

              <div>· 信息互通、数据关联共享</div>
            </div>
            <div class="img">
              <img src="../assets/ww30.png" style="width: 85%;">
            </div>
          </div>
        </div>
      </el-carousel-item>
     <!-- <el-carousel-item v-for="item in 4" :key="item">
        <h3>{{ item }}</h3>
      </el-carousel-item> -->
    </el-carousel>
    <div class="box">
      <ul class="wrap project border">
        <li v-for="(item,index) in syslist" :key="index">
          <div class="item">
            <i :class="item.icon"></i>
            <div class="title">{{item.title}}</div>
            <span class="desc">{{item.desc}}</span>
          </div>
        </li>
      </ul>

    </div>
    <main>
      <ContBolck :title="cont1.title" :sub-title="cont1.subTitle">
        <Cont1 :list="cont1.records"></Cont1>
      </ContBolck>
      <ContBolck :title="cont2.title" :sub-title="cont2.subTitle">
        <Cont2 :list="cont2.records"></Cont2>
      </ContBolck>
      <ContBolck :title="cont3.title" :sub-title="cont3.subTitle">
        <Cont3 :list="cont3.records"></Cont3>
      </ContBolck>
      <ContBolck :title="cont4.title">
        <Cont4 :list="cont4.records"></Cont4>
      </ContBolck>

    </main>
    <Foot></Foot>
  </div>
</template>

<script>
  import Head from '../components/head'
  import Foot from '../components/foot'

  import Cont4 from '../components/content/cont4'
  import Cont3 from '../components/content/cont3'
  import Cont2 from '../components/content/cont2'
  import Cont1 from '../components/content/cont1'
  import ContBolck from '../components/contBolck'
  export default {
    name: 'Home',
    components: {
      Head,
      Foot,

      Cont4,
      Cont3,
      Cont2,
      Cont1,
      ContBolck,


    },
    data() {
      return {

        carousel: {
          height: '520px'
        },
        syslist: [{
            title: '电商系统',
            icon: 'el-icon-shopping-cart-1',
            link: '/ecerp',
            desc: '电商整体解决方案'
          },
          {
            title: 'CRM系统',
            icon: 'el-icon-user',
            link: '/crm',
            desc: '功能强大的CRM系统'
          },
          {
            title: '在线客服系统',
            icon: 'el-icon-service',
            link: '/im',
            desc: '部署简单，功能强大'
          },
          {
            title: '溯源系统',
            icon: 'el-icon-info',
            link: '/trace',
            desc: '产品溯源利器，杜绝窜货'
          }
        ],
        cont1: {
          title: '企赢动力，构建企业级全终端+全场景电商解决方案',
          subTitle: '商城系统全渠道多终端覆盖，完善技术架构支撑企业级服务，支持定制化开发',
          records: [
            {
            title: 'B2C商城',
            txt: '功能强大，安全便捷，可承载千万级访问量；帮助品牌商、零售商快速搭建属于自己的独立网上商城系统。',
            imgPath: require('../assets/h01.jpg')
          }, {
            title: 'B2B商城',
            txt: '帮助供应商整合经销商、终端店和物流商，打造“订货、分销、交易、物流、仓储”一体化的综合B2B电商平台。',
            imgPath: require('../assets/h02.jpg')
          }, {
            title: 'B2B2C',
            txt: '利用最新技术，完美打通各个渠道，实现全终端数据同步，达到全网营销的效果，将所有管理统一于一个管理平台。',
            imgPath: require('../assets/h07.jpg')
          },  
          {
            title: 'O2O新零售系统',
            txt: '新零售系统方案，助企业打破困境提升企业运营效率，优化顾客购物体验，营销精准投放，助企业真正实现线上线下一体化新零售模式',
            imgPath: require('../assets/h04.jpg')
          },{
            title: '分销电商系统',
            txt: '高效分销系统，充分利用社交网络强关系，轻松打造熟人经济。',
            imgPath: require('../assets/h05.jpg')
          }]
        },
        cont2: {
          title: '多元化电商服务和解决方案，满足企业多场景需求',
          subTitle: '为综合领域和垂直行业提供电商解决方案',
          records: [{
              title: 'PC端商城',
              desc: '搭建功能完善的PC端多商户电商平台',
              icon: 'el-icon-monitor'
            }, {
              title: ' WAP触屏商城',
              desc: ' 手机H5网页商城，兼容PC，覆盖移动市场',
              icon: 'el-icon-mobile'
            },

            {
              title: ' 微商城',
              desc: ' 在微信中打造自己的商城系统，全面玩转微信营销',
              icon: 'el-icon-goods'
            },

            {
              title: ' 小程序商城',
              desc: ' 微信等各大平台小程序商城',
              icon: 'el-icon-shopping-bag-1'
            },

            {
              title: ' 原生版APP',
              desc: ' 基于原生开发模式的APP',
              icon: 'el-icon-mobile-phone'
            },

            {
              title: ' 直播商城',
              desc: ' 支持直播+短视频+电商购物的商城系统',
              icon: 'el-icon-video-camera'
            },

            {
              title: ' 供应链S2B2B2C商城',
              desc: ' 集供货商、分销商、批发商、入驻商于一体的综合平台',
              icon: 'el-icon-cpu'
            },

            {
              title: ' 商家版APP',
              desc: ' 移动管理平台，通过手机随时管理商城业务',
              icon: 'el-icon-mobile-phone'
            },

            {
              title: ' 骑手配送APP',
              desc: ' 转为平台打造的一款可以抢单、派单、取货以及提供配送服务的APP',
              icon: 'el-icon-bicycle'
            },

            {
              title: ' 线下收银系统',
              desc: ' 满足多种收款场景',
              icon: 'el-icon-box'
            },
          ]
        },
        cont3: {
          title: '六纬一体服务，助力新零售变革',
          subTitle: '15年行业经验，拥有成熟软件、独立开发团队，为企业提供全方位服务',
          records: [{
            title: '及时响应',
            desc: '电话、工单，售后服务部门随时为你提供迅速响应服务,及时应对客户提出的运营、技术难题',
            icon: 'el-icon-monitor'
          }, {
            title: '技术支持',
            desc: '产品手册、操作教程、使用培训、安装帮助一应俱全，为您提供完整的技术指导，使您快速上手',
            icon: 'el-icon-monitor'
          }, {
            title: '专属定制开发',
            desc: '强大的技术团队，可根据您的项目需求，为您专属定制开发，满足您全方位的产品需求',
            icon: 'el-icon-monitor'
          }, {
            title: '二次开发培训',
            desc: '购买源码版的客户还将获得二次开发说明培训，由专业的技术人员为您授课，使您快速开发上线',
            icon: 'el-icon-monitor'
          }, {
            title: '1v1一体化服务',
            desc: '安排专属售后协助您从域名备案、第三方支付申请、网站安装、后台操作指引、服务器环境配置等等，全程为您提供技术指导',
            icon: 'el-icon-monitor'
          }, {
            title: '更多服务',
            desc: '持续根据客户反馈，满足更多诸如网站迁移、BUG修复、短信购买等疑难问题，以适应您更多服务需求',
            icon: 'el-icon-monitor'
          }]
        },
        cont4: {
          title: '品牌客户案例',
          subTitle: '',
          records: [{
            src:require('../assets/xz.png') 
            },
            {
            src:require('../assets/tsl.jpg') 
            },
            {
            src:require('../assets/tt.png') 
            },
            {
            src:require('../assets/yt.png') 
            },
            {
            src:require('../assets/smgk.png') 
            },
            {
            src:require('../assets/tt.png') 
            },


            {
            src:require('../assets/yw.png') 
            },
            {
            src: require('../assets/ly.png')
            },
            {
            src:require('../assets/jxw.jpg')
            },
            {
            src:require('../assets/jzd.jpg')
            },
            {
            src:require('../assets/j1.jpg') 
            },
            {
            src:require('../assets/msys.jpg')
            }
            ]
        }
      }
    },
    mounted() {

    }
  }
</script>

<style lang="less" scoped>
  .el-carousel-item {
    padding: 50px 0;
  }


  .el-carousel__item.is-animating {
    transition: opacity .2s ease-in-out !important;
  }

  .el-carousel/deep/.el-carousel__indicators {
    bottom: 80px !important;
  }

  .box {
    position: relative;
    height: 120px;
  }

  .banner {padding: 50px 0;

    .wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 420px;
      line-height: 36px;
      color: #FFF;

      h1 {
        font-size: 36px;
        font-weight: bold;
      }

      .subtitle {
        font-size: 20px;
      }

      .img {
        width: 35%;
        overflow: hidden;
        height: 100%;
        display: flex;
        align-items: flex-end;

        img {
          width: 100%;
        }
      }

    }
  }

  .project {
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin: 0 auto !important;
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    z-index: 9;
    height: 120px;
    padding: 20px;

    li {
      width: 25%;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      .title {
        font-size: 18px;
        color: #0080ff;
        line-height: 36px;
      }

      .desc {
        font-size: 14px;
        color: #acb2ba;
      }

      [class^=el-icon-] {
        font-size: 64px;
      }
    }
  }
</style>
