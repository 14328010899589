import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
},
{
  path: '/about',
  name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
},
{
  path: '/ecerp',
  name: 'Ecerp',
  component: () => import('../views/Ecerp.vue')
},
{
  path: '/crm',
  name: 'Crm',
  component: () => import('../views/Crm.vue')
},
{
  path: '/nsale',
  name: 'Nsale',
  component: () => import('../views/Nsale.vue')
},
{
  path: '/im',
  name: 'Im',
  component: () => import('../views/Im.vue')
},
{
  path: '/trace',
  name: 'Trace',
  component: () => import('../views/Trace.vue')
},
{
  path: '/medical',
  name: 'medical',
  component: () => import('../views/medical.vue')
}
]

const router = new VueRouter({
   mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
