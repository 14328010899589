<template>
  <div class="wrap cont1">
    <ul>
      <li v-for="(item,index) in list" :key="index">
        <figure class="item-wrap">
          <img :src="item.imgPath">
          <figcaption>
            <h3 class="title">{{item.title}}</h3>
            <div class="txt">{{item.txt}}</div>            
          </figcaption>
          <div class="top-title">{{item.title}}</div>
        </figure>

      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        show: false,
        cur: 0
      }
    },
    props: {
      list: {
        type: Array,
        default: () => {
          return [{
            title: '',
            txt: '',
            imgPath: ''
          }]
        }
      }
    },
    methods: {
      showInfo(index) {
        this.show = true;
        this.cur = index
      },
      hideInfo() {
        this.show = false;
        this.cur = null;
      }
    }

  }
</script>

<style lang="less" scoped>
  .cont1 {
    ul {
      display: flex;
      justify-content: space-between;

      li {
        width: 20%;
        height: 330px;
        overflow: hidden;
        text-align: center;

        figure {
          position: relative;
          overflow: hidden;

          img {
            transition: transform 0.4s;
            position: relative;
            height: 330px;
            display: block;
            width: 100%;
          }

          &:hover img {
            transform: translateY(-50px);
          }

          &:hover figcaption {
            opacity: 1;
            transform: translateY(0px);
            transition: transform 0.4s, opacity 0.1s;
          }

          .top-title {
            position: absolute;
            height: 40px;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, .45);
            color: #fff;
            line-height: 40px;
            z-index: 1;
          }

          figcaption {
            position: absolute;
            width: 100%;
            height: 85%;
            opacity: 0;
            top: auto;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: #2979ff;
            color: #fff;
            transform: translateY(100%);
            transition: transform 0.4s, opacity 0.1s 0.3s;
            z-index: 2;

            .txt {
              font-size: 14px;
              line-height: 28px;
              text-align: left;
              padding: 10px;
              flex: 1;
            }

            h3 {
              font-size: 18px;
              text-align: center;
              padding-top: 10px;
            }

            .more {
              height: 50px;
              line-height: 50px;
              text-align: right;
              font-size: 14px;
              padding-right: 10px;

              a {
                color: #fff;
              }
            }
          }
        }


      }
    }
  }
</style>