<template>
  <footer>
    <div class="foot">
     
    </div>
    <div class="copyright">
    &copy; 2021 企赢动力 BJQYDL.COM  <a href='https://beian.miit.gov.cn/' target='_blank'>京ICP备14027243号-1</a>  联系邮箱: service@bjqydl.com
      
    </div>
  </footer>
</template>

<script>
  export default {

  }
</script>

<style lang="less" scoped>
  footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #2d2d2d;
    padding-top: 40px;
    text-align: center;
  }

  .foot {
    flex: 1;
  }

  .copyright {
    background: #242424;
    height: 40px;
    color: #a0a0a0;
    font-size: 12px;
    line-height: 40px;
  }
</style>