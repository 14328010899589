<template>
  <div class="cont4 wrap">
    <ul class="parent">
      <li v-for="(item,index) in list" :key="index">
        <img :src="item.src">
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="less" scoped>
  .cont4 {
    padding-bottom: 60px;

    .parent {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 20px;
      

      li {
        border: 1px solid #e2e2e2;
        padding: 1px;
        text-align: center;

        img {
          width: 120px;
        }

        &:hover {
          cursor: pointer;
          border-color: #2979ff;
        }
      }
    }
  }
</style>