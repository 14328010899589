<template>
  <div class="bg">
    <div class="cont2 wrap">
      <ul class="parent">
        <li v-for="(item,index) in list" :key="index">
          <i :class="item.icon"></i>
          <h3 class="title">{{item.title}}</h3>
          <div class="desc">{{item.desc}}</div>
          <div class="btn">
            <el-button round icon="el-icon-chat-dot-round">咨询方案</el-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {

    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="less" scoped>
  .cont2 {
    padding: 60px 0;
  }

  .bg {
    background: #F0F4FC;
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    li {
      background: #FFF;
      padding: 15px 10px;
      position: relative;
      text-align: center;

      &:hover .btn {
        opacity: 1;

        transition: opacity 0.1s;
      }
    }

    .btn {
      position: absolute;
      background: #2979ff;
      top: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.1s;
    }

    .title {
      font-size: 18px;
      line-height: 36px;
    }

    .desc {
      font-size: 14px;
      color: #acb2ba;
      text-align: left;
    }

    [class^=el-icon-] {
      font-size: 48px;
    }
  }
</style>