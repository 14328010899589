<template>
  <section>
    <div class="wrap title-block">
      <h2 class="title">{{title}}</h2>
      <span class="subtitle">{{subTitle}}</span>
    </div>
    <div class="cont-main">
      <slot></slot>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      subTitle: {
        type: String,
        default: ''
      },
    }
  }
</script>

<style lang="less" scoped>
  .title-block {
    padding: 50px 0;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }

  .title {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 18px;
    color: #acb2ba;

  }
</style>